import { createGlobalStyle } from 'styled-components'
import StyledContent from '../../components/Content/style'

export const PageWiderContentStyle = createGlobalStyle`
    ${StyledContent} {
        p,
        ul,
        ol {
            max-width: 100%;
        }
    }
`
