import { createGlobalStyle } from 'styled-components'
import StyledHeader from '../../views/Header/style'
import StyledFooter from '../../views/Footer/style'
export const HidePageHeaderFooterStyle = createGlobalStyle`
 ${StyledHeader}, ${StyledFooter} {
     display:none;
 }
 body {
    padding-top:0;
 }

`
