import React from 'react'
import PropTypes from 'prop-types'
import StyledBreadcrumbs from './style'
import { getUrl, SITE_URL } from '../../helpers/url'

/**
 * Fix absolute url
 * @param {*} url
 */
const fixAbsoluteUrl = url => {
  if (url.startsWith('/')) {
    return SITE_URL + url
  }
  return url
}

const fixHtmlEnteties = text => {
  text = text.replace(/&amp;/g, '&')
  text = text.replace(/&#8211;/g, '-')
  return text
}

const Breadcrumbs = ({ breadcrumbs, colorTheme, mobileColorTheme }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((item, key) => {
      const url = key === 0 ? SITE_URL : fixAbsoluteUrl(item.url)
      return {
        '@type': 'ListItem',
        position: key + 1,
        name: item.text,
        item: url,
      }
    }),
  }

  return (
    <>
      {schema && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      )}

      {breadcrumbs.length > 1 && (
        <StyledBreadcrumbs
          aria-label='breadcrumb'
          colorTheme={colorTheme}
          mobileColorTheme={mobileColorTheme}
        >
          <StyledBreadcrumbs.List>
            {breadcrumbs.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {key > 0 && (
                    <StyledBreadcrumbs.ListSeperator>
                      {' '}
                      /{' '}
                    </StyledBreadcrumbs.ListSeperator>
                  )}
                  {key === 0 && (
                    <StyledBreadcrumbs.ListItem>
                      <Breadcrumb
                        to={SITE_URL}
                        text={fixHtmlEnteties(item.text)}
                        currentPage={key === breadcrumbs.length - 1}
                      />
                    </StyledBreadcrumbs.ListItem>
                  )}
                  {key > 0 && (
                    <StyledBreadcrumbs.ListItem>
                      <Breadcrumb
                        to={getUrl(item.url)}
                        text={fixHtmlEnteties(item.text)}
                        currentPage={key === breadcrumbs.length - 1}
                      />
                    </StyledBreadcrumbs.ListItem>
                  )}
                </React.Fragment>
              )
            })}
          </StyledBreadcrumbs.List>
        </StyledBreadcrumbs>
      )}
    </>
  )
}

const Breadcrumb = props => {
  const { currentPage, to, text } = props

  return currentPage ? (
    <StyledBreadcrumbs.Item aria-current={'page'}>
      {text}
    </StyledBreadcrumbs.Item>
  ) : (
    <StyledBreadcrumbs.Link to={to}>{text}</StyledBreadcrumbs.Link>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
}

export { Breadcrumbs }
