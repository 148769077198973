import React, { useState } from 'react'
import StyledSubscribeForm from './style'
import PropTypes from 'prop-types'
import { Form } from '../Form'
import { Text } from '../Text'
import { Wrapper } from '../Wrapper'
import { Button } from '../Button'
import { Loader } from '../Loader'
import { sendNewsletterForm } from '../../helpers/api'
import { COLORS } from '../../helpers/constants'
import { getString } from '../../helpers/lang'
import { pushDataLayer } from '../../helpers/tracking'

import { setCookie } from '../../helpers/storage'
import { hash } from '../../helpers/utilities'

const SubscribeForm = ({ formSubmitted }) => {
  const [email, setEmail] = useState('')
  const [consent, setConsent] = useState(false)
  const [attempted, setAttempted] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [status, setStatus] = useState({})

  const onSubmit = e => {
    e.preventDefault()

    setAttempted(true)

    if (!consent) {
      return
    }

    if (!formLoading) {
      setFormLoading(true)
      sendNewsletterForm({ email: email })
        .then(() => {
          setStatus({
            status: 'success',
            message: getString('NEWSLETTER_MESSAGE_SUCCESS'),
          })
          pushDataLayer({
            event: 'formSubmission',
            formType: 'Newsletter Signup',
            customer_email: email,
            customer_email_sha256: hash(email)
          })
        })
        .catch(() => {
          setStatus({
            status: 'error',
            message: getString('FORM_ERROR_TITLE'),
          })
        })
        .finally(() => {
          setFormLoading(false)
          formSubmitted()
          setCookie('newsletter-subscribed', true, 30)
        })
    }
  }

  return (
    <StyledSubscribeForm onSubmit={onSubmit}>
      <Wrapper>
        {formLoading ? (
          <Loader negative />
        ) : (
          <>
            {status.status === 'success' ? null : status.status === 'error' ? ( // <Text color={COLORS.WHITE}>{status.message}</Text>
              <Text color={COLORS.WHITE}>{status.message}</Text>
            ) : (
              <>
                <Form.Input
                  type={'email'}
                  required
                  placeholder={getString('FOOTER_NEWSLETTER_EMAIL')}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <Button secondary>{getString('FOOTER_NEWSLETTER_SEND')}</Button>
              </>
            )}
          </>
        )}
      </Wrapper>

      {!formLoading && !status?.status && (
        <>
          <StyledSubscribeForm.Consent>
            <Form.Checkbox
              label={getString('NEWSLETTER_CONSENT_TEXT')}
              checked={consent}
              name={'consent'}
              onChange={e => setConsent(e.target.checked)}
            />
          </StyledSubscribeForm.Consent>
          {!consent && attempted && (
            <Text color={COLORS.WHITE} size={'small'}>
              {getString('NEWSLETTER_CONSENT_VALIDATION')}
            </Text>
          )}
        </>
      )}
    </StyledSubscribeForm>
  )
}

SubscribeForm.propTypes = {
  formSubmitted: PropTypes.func,
}

export { SubscribeForm }
