import styled, { css } from 'styled-components'
import {
  typography,
  colors,
  spacing,
  mq,
} from '../../styles/utilities/variables'

export const StyledStickyButton = styled.div`
  bottom: ${spacing.base};
  position: sticky;
  z-index: 5;
  padding: ${spacing.base};
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  @media ${mq.mobile} {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    bottom: 0;
    ${props =>
      props.inViewMobile &&
      css`
        opacity: 1;
        pointer-events: default;
      `}
  }

  ${props =>
    props.inView &&
    css`
      position: absolute;
      bottom: 0;
      right: 0;
      left: unset;
    `}
  a {
    color: ${colors.black};
    background-color: ${colors.nbSix};
    padding: ${spacing.base} ${spacing.medium};
    border-radius: 3rem;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: ${typography.sizes.small};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    pointer-events: auto;
  }
`
