import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
  colors,
  maxWidths,
  mq,
  spacing,
} from '../../styles/utilities/variables'
import StyledIcon from '../Icon/style'
import StyledSubscribeForm from '../SubscribeForm/style'
import StyledWrapper from '../Wrapper/style'
import StyledButton from '../Button/style'

const customMq = `${parseInt(maxWidths.wideContainer.substring(0, 4)) + 250}px`

const StyledStickyModal = styled(motion.div)`
  position: absolute;
  top: ${({ position }) => (position ? `${position}px` : `150vh`)};
  left: calc(
    ((100vw - ${maxWidths.wideContainer} - (${spacing.large} * 2)) / 2) - 100px
  );
  max-width: 512px;
  z-index: 2;
  background-color: ${colors.primary};
  padding: ${spacing.xLarge};
  box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 20px;

  @media (max-width: ${customMq}) {
    left: 16px;
  }

  @media (${mq.mobile}) {
    max-width: 100%;
    right: 20px;
    left: 20px;
  }

  ${StyledIcon} {
    cursor: pointer;
    position: absolute;
    top: ${spacing.medium};
    right: ${spacing.medium};
  }

  ul {
    padding-left: ${spacing.base};

    li {
      margin-bottom: 0;
      p {
        font-weight: 500;
        margin-bottom: 0;
      }
      &::marker {
        color: ${colors.white};
      }
    }
  }

  ${StyledSubscribeForm} {
    ${StyledWrapper} {
      flex-direction: column;
    }
    ${StyledButton} {
      margin-top: ${spacing.large};
      width: fit-content;
    }
    input {
      font-weight: 500;
      color: ${colors.white};
      border-radius: 0px;
      padding: ${spacing.xSmall} 0;
      margin-right: ${spacing.large};
      border-color: ${colors.secondary};
    }
  }
`

export { StyledStickyModal }
