import React from "react"
import { graphql } from "gatsby"
import { parseAcfHead, parseSeo } from "../helpers/seo"
import { WpBlocksGenerator } from "../helpers/wpBlocksGenerator"
import { PageContent } from "../components/PageContent"
import Layout from "../views/Layout"
import SEO from "../views/Seo"
import { HidePageHeaderFooterStyle } from "../styles/base/hidePageHeaderFooterStyles"
import { PageWiderContentStyle } from "../styles/base/pageWiderContentStyles"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { getPreloadData } from "../helpers/image"
import StickyButton from "../components/StickyButton"
import { getCurrentLang } from "../helpers/lang"
import StickyModal from "../components/StickyModal"

export default ({ data }) => {
  const page = data.wordpress.page
  const { blocks = [], seo, isFrontPage, acf, acfHead } = page
  const hero =
    blocks && blocks.length && blocks[0].name === "acf/hero" ? blocks[0] : null
  const colorTheme = hero ? "light" : "dark"

  const mobileColorTheme =
    blocks && blocks.length && blocks[0].acf?.heroLayout === "shapes"
      ? "white"
      : "black"
  /**
   * Get preload hero image data
   */
  const preloadHero =
    hero && hero.acf.heroBgImage && hero.acf.heroBgImage
      ? getPreloadData(hero.acf.heroBgImage.makeImage)
      : null
  const { schema, hrefLangs } = parseAcfHead(acfHead)

  const currentLang = getCurrentLang()
  const swedishLang = currentLang === "sv" || currentLang === "sv_SE"

  /**
   * Check if page is tjana-pengar
   */
  const isEarnMoneyPage = page.slug === "tjana-pengar" ? "earnMoney" : null

  /**
   * Check if sticky components should be shown on the page
   */
  const showStickyButton = acf.pageShowStartSaleButton ? true : false
  const showStickyModal = acf.pageShowNewsletterModal ? true : false

  return (
    <Layout>
      <SEO
        {...parseSeo(seo)}
        preloadImage={preloadHero}
        schema={schema}
        hrefLangs={hrefLangs}
      />
      {acf && acf.pageHideHeaderFooter && <HidePageHeaderFooterStyle />}
      {acf && acf.pageWiderContent && <PageWiderContentStyle />}
      <PageContent template={isEarnMoneyPage}>
        {!isFrontPage && !acf.pageHideBreadcrumbs && (
          <Breadcrumbs
            colorTheme={colorTheme}
            breadcrumbs={seo.breadcrumbs}
            mobileColorTheme={mobileColorTheme}
          />
        )}
        <WpBlocksGenerator blocks={blocks} post={page} />
        {showStickyModal && swedishLang && <StickyModal />}
        {showStickyButton && <StickyButton />}
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
      }
    }
  }
`
