import React, { useState, useEffect, useRef, Component } from 'react'
import { StyledStickyButton } from './style'
import { getString } from '../../helpers/lang'
import { Link } from '../Link'
import { getIsClient } from '../../helpers/utilities'

const StickyButton = () => {
  const [inView, setInView] = useState(false)
  const [inViewMobile, setInViewMobile] = useState(false)
  const [windowScroll, setWindowScroll] = useState(null)

  useEffect(() => {
    if (getIsClient) {
      const handleScroll = () => {
        setWindowScroll(window.scrollY)
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const mainElement = document.querySelector('main')
    const mainHeight = mainElement.scrollHeight

    // 32 to count for padding
    if (window.scrollY + window.innerHeight - 32 > mainHeight) {
      setInView(true)
    } else {
      setInView(false)
    }
    if (window.scrollY > 500) {
      setInViewMobile(true)
    } else {
      setInViewMobile(false)
    }
  }, [windowScroll])

  return (
    <StyledStickyButton inView={inView} inViewMobile={inViewMobile}>
      <Link to={getString('SELLER_LINK')}>
        {getString('SELLER_SELL_WITH_US')}
      </Link>
    </StyledStickyButton>
  )
}

export default StickyButton
