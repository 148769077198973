import { Content } from '../Content'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { SubscribeForm } from '../SubscribeForm'
import { Text } from '../Text'
import { getCookie, setCookie } from '../../helpers/storage'
import { getIsClient } from '../../helpers/utilities'
import { useResponsive } from '../../hooks/useResponsive'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { StyledStickyModal } from './style'

const StickyModal = () => {
  const ref = useRef(null)
  const [inView, setInView] = useState(false)
  const [windowScroll, setWindowScroll] = useState(null)
  const [distance, setDistance] = useState(null)
  const [show, setShow] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [promptPosition, setPromptPosition] = useState(null)
  const { windowSize } = useResponsive()

  const CLOSED_COOKIE_NAME = 'closed-newsletter-prompt'
  const SUBSCRIBED_COOKIE_NAME = 'newsletter-subscribed'

  useEffect(() => {
    if (getIsClient()) {
      if (!getCookie(CLOSED_COOKIE_NAME)) {
        const isSubscribed = getCookie(SUBSCRIBED_COOKIE_NAME) !== null
        setShow(isSubscribed ? false : true)
      }
    }
  }, [])

  useEffect(() => {
    if (getIsClient() && ref.current && show) {
      const footerHeight = document.querySelector('footer')
        ? document.querySelector('footer').clientHeight
        : 0

      const pageHeight = document.body.clientHeight

      const maxHeight =
        pageHeight - footerHeight - ref.current.clientHeight - 400

      let position = Math.round(Math.random() * maxHeight)

      if (position < window.innerHeight) position += window.innerHeight

      setPromptPosition(position)

      const io = new IntersectionObserver(e => {
        const entry = e.pop()
        if (entry.isIntersecting) {
          setInView(true)
        } else {
          setInView(false)
        }
      })

      io.observe(ref.current)

      const handleScroll = () => {
        setWindowScroll(window.scrollY)
      }

      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [ref, show])

  useEffect(() => {
    if (inView && show) {
      const middle = windowScroll + windowSize.height / 2
      const distanceInPixels =
        middle - (ref.current.offsetTop + ref.current.clientHeight / 2)
      setDistance(
        distanceInPixels /
          (windowSize.height / 2 + ref.current.clientHeight / 2)
      )
    }
  }, [windowScroll, show])

  return (
    <AnimatePresence>
      {show && (
        <StyledStickyModal
          ref={ref}
          initial={{ translateY: 0 }}
          animate={{ translateY: -100 * distance }}
          exit={{ opacity: 0 }}
          position={promptPosition}
        >
          <Icon
            icon='times'
            color='white'
            handleClick={() => {
              setShow(false)
              setCookie(CLOSED_COOKIE_NAME, true, 30)
            }}
          />
          <Content>
            <Heading color='white' size={'large'}>
              {!formSubmitted
                ? 'Prenumerera på vårt nyhetsbrev'
                : 'Tack så mycket för din prenumeration!'}
            </Heading>
            <ul>
              <li>
                <Text color='white' size={'small'}>
                  Som prenumerant på vårt nyhetsbrev får du tips och inspiration
                  och är först med att få reda på produktlanseringar och annat
                  kul som händer hos oss.
                </Text>
              </li>
              <li>
                <Text color='white' size={'small'}>
                  Stötta barn och ungdomar i Sverige
                </Text>
              </li>
              <li>
                <Text color='white' size={'small'}>
                  Håll dig uppdaterad om våra nyheter
                </Text>
              </li>
            </ul>
            <SubscribeForm
              formSubmitted={() => {
                setFormSubmitted(true)
              }}
            />
          </Content>
        </StyledStickyModal>
      )}
    </AnimatePresence>
  )
}

export default StickyModal
