import { colors, spacing, typography } from '../../styles/utilities/variables'
import styled from 'styled-components'
import StyledForm from '../Form/style'
import StyledButton from '../Button/style'

const StyledSubscribeForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledForm.Input} {
    background: transparent;
    border-right: none;
    border-top: none;
    border-left: none;
    font-size: ${typography.sizes.small};
    &::placeholder {
      color: ${colors.white};
    }
  }
  ${StyledButton} {
    line-height: 1;
  }
  ${StyledForm.Checkbox} {
    align-items: start;
    input {
      background-color: transparent;

      &:checked ~ .checkmark {
        background-color: #2196f3;
      }
    }
  }
`

StyledSubscribeForm.Consent = styled.div`
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.xSmall};
  color: ${colors.white};

  input {
    margin-right: ${spacing.small} !important; // Sorry
  }

  .label {
    line-height: 1.5;

    a {
      color: ${colors.white};
      text-decoration: underline;
    }
  }
`

export default StyledSubscribeForm
